import React from "react";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiCalendly } from "react-icons/si";
import { ShieldCheckIcon } from "./SVG/Shield-check";

function Contact() {
  return (
    <div className="z-20 relative min-h-screen flex flex-col justify-between">
      <div className="flex flex-col items-center justify-evenly">
        <div className="text-5xl font-bold mt-16">Contact Us</div>
        <div className="flex flex-col-reverse md:flex-row w-full justify-between">
          <div className="flex flex-col m-16 gap-8">
            <div className="flex flex-col">
              <div className="flex items-center font-bold text-3xl">
                <MdEmail />
                &nbsp;
                <div>Email</div>
              </div>
              <div className="pl-10">
                <a
                  href="mailto:no@thankyou.com"
                  className="decoration-accent-600 hover:underline underline-offset-2"
                >
                  no@thankyou.com
                </a>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center font-bold text-3xl">
                <FaWhatsapp />
                &nbsp;
                <div>WhatsApp</div>
              </div>
              <div className="pl-10">
                <a
                  href="https://wa.me/91987XXXXXXX"
                  className="decoration-accent-600 hover:underline underline-offset-2"
                >
                  +91-987XXXXXXX
                </a>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center font-bold text-3xl">
                <SiCalendly />
                &nbsp;
                <div>Calendly</div>
              </div>
              <div className="pl-10">
                <a
                  href="https://calendly.com/"
                  className="decoration-accent-600 hover:underline underline-offset-2"
                >
                  /myCalLink
                </a>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center font-bold text-3xl">
                <FaLinkedin />
                &nbsp;
                <div>LinkedIn</div>
              </div>
              <div className="pl-10">
                <a
                  href="https://www.linkedin.com"
                  className="decoration-accent-600 hover:underline underline-offset-2"
                >
                  /myLinkedInHere
                </a>
              </div>
            </div>
          </div>
          <div className="z-20 relative ml-[10%] md:ml-[25%] mt-16 w-full min-h-80 rounded-s-3xl bg-primary-100 shadow-xl">
            <div className="absolute -z-10 top-[20%] right-0">
              <ShieldCheckIcon />
            </div>
            <div className="p-16 flex flex-col">
              <div className="text-3xl font-bold pb-4">
                Perks of working with us
              </div>
              <div>
                <ul className="list-disc list-inside leading-10">
                  <li>Perk 1</li>
                  <li>Perk 2</li>
                  <li>Perk 3</li>
                  <li>Perk 4</li>
                  <li>Perk 5</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center my-8 border-t border-t-background-400">
        <div className="mt-6">© 2024 DigiUP Media Private Limited</div>
        <div>
          <a
            href="https://google.com"
            className="m-2 decoration-accent-600 hover:underline underline-offset-2"
          >
            Terms of Service
          </a>
          |
          <a
            href="https://google.com"
            className="m-2 decoration-accent-600 hover:underline underline-offset-2"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
