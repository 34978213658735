import React from "react";
import { FaArrowRight } from "react-icons/fa";
import image from "../images/hero_image.png";
import ThemeSwitch from "./ThemeSwitcher";
import { GripIcon } from "./SVG/Grip";

function Hero() {
  return (
    <div className="z-10 relative h-screen flex md:flex-row flex-col-reverse md:justify-between items-center">
      <div className="absolute -top-4 -left-16 md:top-0 md:left-0">
        <GripIcon />
      </div>
      <div className="absolute -bottom-4 -right-16 md:bottom-0 md:right-0 rotate-90">
        <GripIcon />
      </div>
      <div className="flex flex-col p-16 h-100 justify-center">
        <div className="p-4 border-secondary-500 border rounded-2xl">
          <div className="text-lg italic">We are</div>
          <div className="text-5xl font-bold">DigiUP</div>
          <div>
            A&nbsp;
            <span className="font-bold italic">
              business development agency
            </span>
          </div>
        </div>
        <div className="pt-8 pb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Reprehenderit, optio!
        </div>
        <div>
          <button
            onClick={() => {
              const element = document.getElementById("contact");
              element?.scrollIntoView({ behavior: "smooth" });
            }}
            className="flex items-center group bg-accent-300 hover:bg-accent-200 duration-150 p-2 px-4 rounded-full"
          >
            Contact Us&nbsp;
            <FaArrowRight className="group-hover:rotate-90 transition-all duration-300 ease-in-out" />
          </button>
        </div>
      </div>
      <div className="p-16">
        <div className="absolute top-[10%] right-0 bg-background-100 rounded-l-full">
          <div className="p-2 pt-3 md:pl-3">
            <ThemeSwitch />
          </div>
        </div>
        <img src={image} alt="" />
      </div>
    </div>
  );
}

export default Hero;
