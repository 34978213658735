import Contact from "./components/Contact+Footer";
import Hero from "./components/Hero";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
// import stars from "./images/stars.png";
import { useState, useEffect } from "react";
import image from "./images/portfolio_image.jpg";

function App() {
  useEffect(() => {
    changeCategory("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [tempCards, setTempCards] = useState([]);
  const [category, setCategory] = useState("all");
  const cards = [
    {
      title: "Project 1",
      subtitle: "Subtitle 1",
      image: image,
      category: "cat-1",
      link: "https://www.google.com",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, optio!",
    },
    {
      title: "Project 2",
      subtitle: "Subtitle 2",
      image: image,
      category: "cat-1",
      link: "https://www.google.com",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, optio!",
    },
    {
      title: "Project 3",
      subtitle: "Subtitle 3",
      image: image,
      category: "cat-2",
      link: "https://www.google.com",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, optio!",
    },
    {
      title: "Project 4",
      subtitle: "Subtitle 4",
      image: image,
      category: "cat-3",
      link: "https://www.google.com",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, optio!",
    },
  ];
  function changeCategory(category) {
    shiftFocusToActiveElement(category);
    if (category === "all") {
      setTempCards(cards);
    } else {
      const temp = cards.filter((card) => {
        return card.category === category;
      });
      setTempCards(temp);
    }
  }
  function shiftFocusToActiveElement(_category) {
    document
      .getElementById(category)
      .classList.remove("underline", "text-accent");
    setCategory(_category);
    document
      .getElementById(_category)
      .classList.add("underline", "text-accent");
  }
  return (
    <div className="overflow-x-hidden">
      <div className="bg-gradient-to-b from-accent-500 to-background-50 text-text-950">
        <Hero />
        {/* <img src={stars} alt="" className="absolute bottom-0 z-0" /> */}
      </div>
      <div className="bg-background-50 text-text-950">
        <Services changeCategory={changeCategory} />
      </div>
      <div className="bg-background-50 bg-gradient-to-b from-background-50 to-accent-400 text-text-950">
        <Portfolio changeCategory={changeCategory} tempCards={tempCards} />
      </div>
      <div
        id="contact"
        className="bg-background-200 bg-gradient-to-b from-accent-400 to-background-50 text-text-950"
      >
        <Contact />
      </div>
    </div>
  );
}

export default App;
