import React from "react";
import Button from "../Button";

function ServiceCard(props) {
  return (
    <div className="bg-secondary-500 rounded-xl text-white dark:text-black select-none p-4 h-full">
      <div className="p-4 flex flex-col h-full justify-between">
        <div>
          <div className="p-4 border-secondary-200 border rounded-2xl justify-start">
            <div className="text-3xl font-bold">{props.title}</div>
            <div>
              <span className="font-bold italic">{props.subtitle}</span>
            </div>
          </div>
          <div className="p-4">{props.body}</div>
        </div>
        <div className="self-end">
          <Button onClick={props.onClick} text="Jump to section"></Button>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
