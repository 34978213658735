import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Card from "./Cards/PortfolioCard";
import { WorkflowIcon } from "./SVG/Workflow";

function Portfolio(props) {
  return (
    <div
      id="portfolio"
      className="z-20 relative flex flex-col h-max items-center justify-center px-10 py-16 md:p-16 md:pb-0"
    >
      <div className="-z-10 absolute -bottom-72 -left-36">
        <WorkflowIcon />
      </div>
      <div className="text-5xl font-bold">Portfolio</div>
      <div className="flex text-center py-8 select-none">
        {/* Categories */}
        <button
          id="all"
          onClick={() => {
            props.changeCategory("all");
          }}
          className="p-4 decoration-accent-600 hover:underline underline-offset-2 cursor-pointer"
        >
          All
        </button>
        <button
          id="cat-1"
          onClick={() => {
            props.changeCategory("cat-1");
          }}
          className="p-4 decoration-accent-600 hover:underline underline-offset-2 cursor-pointer"
        >
          Category 1
        </button>
        <button
          id="cat-2"
          onClick={() => {
            props.changeCategory("cat-2");
          }}
          className="p-4 decoration-accent-600 hover:underline underline-offset-2 cursor-pointer"
        >
          Category 2
        </button>
        <button
          id="cat-3"
          onClick={() => {
            props.changeCategory("cat-3");
          }}
          className="p-4 decoration-accent-600 hover:underline underline-offset-2 cursor-pointer"
        >
          Category 3
        </button>
      </div>
      <motion.div layout className="grid md:grid-cols-3">
        <AnimatePresence>
          {props.tempCards.map((card, index) => {
            return (
              <Card
                key={index}
                title={card.title}
                subtitle={card.subtitle}
                image={card.image}
                category={card.category}
                link={card.link}
                description={card.description}
              />
            );
          })}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}

export default Portfolio;
