function Button(props) {
  return (
    <button
      onClick={props.onClick}
      className="mx-6 bg-accent-300 hover:bg-accent-200 duration-150 text-text-950 p-2 px-4 rounded-full"
    >
      {props.text}
    </button>
  );
}

export default Button;
