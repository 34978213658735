// ThemeSwitch.tsx
import React, { useEffect } from "react";
import { Switch } from "@headlessui/react";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ThemeSwitch() {
  const [dark, setDark] = React.useState(true);
  const darkModeHandler = () => {
    setDark(!dark);
    document.body.classList.toggle("dark");
  };

  function isPhone() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  useEffect(() => {
    document.body.classList.toggle("dark", true);
  }, []);

  return (
    <Switch
      checked={dark}
      onChange={darkModeHandler}
      className={classNames(
        dark ? "bg-background-400" : "bg-accent-600",
        "relative inline-flex h-6 md:h-10 w-[2.7rem] md:w-[4.7rem] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out",
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          dark
            ? "translate-x-[1.2rem] md:translate-x-[2.2rem]"
            : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 md:h-9 md:w-9 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
        )}
      >
        <span
          className={classNames(
            dark
              ? "opacity-0 duration-100 ease-out"
              : "opacity-100 duration-200 ease-in",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
          )}
          aria-hidden="true"
        >
          {isPhone() ? (
            <MdOutlineLightMode className="text-black" size="1rem" />
          ) : (
            <MdOutlineLightMode className="text-black" size="1.5rem" />
          )}
        </span>
        <span
          className={classNames(
            dark
              ? "opacity-100 duration-200 ease-in"
              : "opacity-0 duration-100 ease-out",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
          )}
          aria-hidden="true"
        >
          {isPhone() ? (
            <MdOutlineDarkMode className="text-black" size="1rem" />
          ) : (
            <MdOutlineDarkMode className="text-black" size="1.5rem" />
          )}
        </span>
      </span>
    </Switch>
  );
}

export default ThemeSwitch;
