import React from "react";
import { motion } from "framer-motion";

function PortfolioCard(props) {
  return (
    <motion.a
      layout
      animate={{ opacity: 1, translateY: 0 }}
      initial={{ opacity: 0, translateY: -100 }}
      exit={{ opacity: 0, translateY: 100 }}
      transition={{ duration: 0.3 }}
      className="relative z-0 overflow-hidden text-black dark:text-white bg-background-100 border-accent-600 border rounded-xl my-4 md:m-8 drop-shadow-xl md:w-auto w-full before:absolute before:z-[-1] before:-bottom-4 before:-right-4 before:border-accent-400 before:rounded-tl-xl before:w-12 before:h-12 before:border-[32px] before:scale-100 before:origin-center before:duration-500 before:hover:scale-[21]"
      href="#"
    >
      <div className="flex flex-col md:p-8 py-8 px-6 justify-center">
        <div className="mx-2 p-4 border-secondary-900 border rounded-2xl">
          <img
            className="mb-4 rounded-2xl select-none"
            src={props.image}
            alt=""
          />
          <div className="border border-background-600"></div>
          <div className="py-2">
            <div className="text-3xl font-bold">{props.title}</div>
            <div>{props.subtitle}</div>
          </div>
        </div>
        <div className="mx-6 pt-8 pb-4">{props.description}</div>
        <div className="flex items-center justify-center absolute w-12 h-12 overflow-hidden bottom-0 right-0 bg-accent-400 rounded-br-xl rounded-tl-xl">
          <div className="text-4xl">→</div>
        </div>
      </div>
    </motion.a>
  );
}

export default PortfolioCard;
