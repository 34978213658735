import React from "react";
import { EffectCoverflow, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "../index.css";
import Card from "./Cards/ServiceCard";
import { RouteIcon } from "./SVG/Route";

function Services(props) {
  function onCardClick(category) {
    props.changeCategory(category);
    const element = document.getElementById("portfolio");
    element?.scrollIntoView({ behavior: "smooth" });
  }
  const cards = [
    {
      title: "App/Web Development",
      subtitle: "by professionals",
      body: "something else",
      category: "cat-1",
    },
    {
      title: "Photo/Video Editing",
      subtitle: "hello",
      body: "something",
      category: "cat-2",
    },
    {
      title: "Digital/Offline Marketing",
      subtitle: "hello",
      body: "something",
      category: "cat-3",
    },
    {
      title: "Business Development",
      subtitle: "hello",
      body: "something",
      category: "cat-1",
    },
    {
      title: "YouTube Management",
      subtitle: "hello",
      body: "something",
      category: "cat-3",
    },
    {
      title: "Search Engine Optimization",
      subtitle: "hello",
      body: "something",
      category: "cat-2",
    },
    {
      title: "Content Creation",
      subtitle: "hello",
      body: "something",
      category: "cat-3",
    },
  ];
  return (
    <div className="relative z-20">
      <div>
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#1f2937"
            fillOpacity="1"
            d="M0,128L40,160C80,192,160,256,240,240C320,224,400,128,480,90.7C560,53,640,75,720,101.3C800,128,880,160,960,149.3C1040,139,1120,85,1200,69.3C1280,53,1360,75,1400,85.3L1440,96L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          ></path>
        </svg> */}
      </div>
      <div className="absolute top-0 -z-10 left-0 md:left-1/4">
        <RouteIcon />
      </div>
      <div className="p-16 flex justify-center">
        <span className="bg-clip-text font-bold text-5xl text-transparent bg-gradient-to-r from-text-700 to-text-900">
          Our Services
        </span>
      </div>
      <div className="pt-16 px-8 pb-16 md:pb-0 md:px-0">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          modules={[Pagination, EffectCoverflow, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          initialSlide={3}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 150,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={{
            clickable: true,
            bulletClass: "swiper-pagination-custom-bullet",
            bulletActiveClass: "swiper-pagination-custom-bullet-active",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {cards.map((card, index) => {
            return (
              <SwiperSlide className="mb-16 !h-auto">
                <Card
                  key={index}
                  title={card.title}
                  subtitle={card.subtitle}
                  body={card.body}
                  onClick={() => onCardClick(card.category)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div>
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#334155"
            fillOpacity="1"
            d="M0,256L48,266.7C96,277,192,299,288,304C384,309,480,299,576,272C672,245,768,203,864,165.3C960,128,1056,96,1152,117.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg> */}
      </div>
    </div>
  );
}

export default Services;
